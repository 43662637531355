document.addEventListener("DOMContentLoaded", function (event) {
	const tabStyles = [
		{
			selector: ".tabs-styleOne",
			childSelector: ".tab-dot-icon",
			activeClass: "text-secondary",
			inactiveClass: "text-body",
			childActiveClass: "bg-secondary",
			childInactiveClass: "bg-semiGray",
		},
		{
			selector: ".tabs-styleTwo",
			activeClass: "text-primary border-primary",
			inactiveClass: "text-darkGray border-white",
		},
		{
			selector: ".tabs-styleThree",
			activeClass: "text-secondary",
			inactiveClass: "text-body",
		},
	];

	let currentTabIndex = 0;

	function updateTabClasses(newIndex) {
		tabStyles.forEach((style) => {
			const tabs = document.querySelectorAll(
				`${style.selector} button[id^="tab-"]`,
			);
			const contents = document.querySelectorAll(
				`${style.selector} div[id^="content-tab-"]`,
			);

			if (tabs.length > 0) {
				tabs.forEach((t, index) => {
					if (index === newIndex) {
						t.classList.remove(...style.inactiveClass.split(" "));
						t.classList.add(...style.activeClass.split(" "));
						if (t.querySelector(style.childSelector)) {
							t.querySelector(style.childSelector).classList.remove(...style.childInactiveClass.split(" "));
							t.querySelector(style.childSelector).classList.add(...style.childActiveClass.split(" "));
						}
					} else {
						t.classList.remove(...style.activeClass.split(" "));
						t.classList.add(...style.inactiveClass.split(" "));
						if (t.querySelector(style.childSelector)) {
							t.querySelector(style.childSelector).classList.remove(...style.childActiveClass.split(" "));
							t.querySelector(style.childSelector).classList.add(...style.childInactiveClass.split(" "));
						}
					}
				});

				contents.forEach((content, index) => {
					if (index === newIndex) {
						content.classList.remove("hidden");
					} else {
						content.classList.add("hidden");
					}
				});
			}
		});
	}

	function switchTab(direction) {
		const tabs = document.querySelectorAll('.tab-content');
		let activeTabIndex = Array.from(tabs).findIndex(tab => !tab.classList.contains('hidden'));
	
		tabs[activeTabIndex].classList.add('hidden');
	
		if (direction === 'next') {
			activeTabIndex = (activeTabIndex + 1) % tabs.length;
		} else {
			activeTabIndex = (activeTabIndex - 1 + tabs.length) % tabs.length;
		}
	
		tabs[activeTabIndex].classList.remove('hidden');
	
		// Update the active state of the tab buttons
		document.querySelectorAll('.tab-button').forEach((btn, index) => {
			if (index === activeTabIndex) {
				btn.classList.add('active');
			} else {
				btn.classList.remove('active');
			}
		});

		updateTabClasses(activeTabIndex);
	}

	tabStyles.forEach((style) => {
		const tabs = document.querySelectorAll(
			`${style.selector} button[id^="tab-"]`,
		);

		if (tabs.length > 0) {
			tabs.forEach((tab, index) => {
				tab.addEventListener("click", () => {
					currentTabIndex = index;
					updateTabClasses(currentTabIndex);
				});
			});
		}
	});

	window.switchTab = switchTab;
});